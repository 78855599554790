import { combineReducers } from 'redux';

//import siteConfigReducer from './slices/siteConfig.slice';
import siteConfigReducer from './slices/siteConfig.slice';
import plateformConfigReducer from './slices/plateformConfig.slice';
import menuConfigReducer from './slices/menuConfig.slice';
import isLoggedInReducer from './slices/isLoggedIn.slice';
import singupConfigReducer from './slices/singupConfig.slice';
import isSocketUMLoadingReducer from './slices/isSocketUMLoading.slice';
import isSocketPMLoadingReducer from './slices/isSocketPMLoading.slice';
import carouselConfigRaducer from './slices/carouselConfig.slice';

const rootReducer = combineReducers({

    siteConfig : siteConfigReducer,
    plateformConfig : plateformConfigReducer,
    menuConfig : menuConfigReducer,
    isLoggedIn : isLoggedInReducer,
    singupConfig : singupConfigReducer,
    isSocketUMLoading : isSocketUMLoadingReducer,
    isSocketPMLoading : isSocketPMLoadingReducer,
    carouselConfig : carouselConfigRaducer
    

});


export default rootReducer;
