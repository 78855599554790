import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClose,
  faHouse,
  faIdCard,
  faKey,
  faLocationDot,
  faPerson,
  faUsersBetweenLines,
} from '@fortawesome/free-solid-svg-icons'

import { useSiteConfig } from '../../reduxhooks/ReduxHooks';
import { useTranslation } from 'react-i18next';

export default function MenuItems() {

  const configValue = useSiteConfig();
  const { t } = useTranslation();

  const menuConnected = [
    {
      label: t('landingPage.mainMenu.home'),
      key: 'home',
      icon: <FontAwesomeIcon icon={faHouse} style={{ color: configValue.iconColor }} />,
      path: '/',
      type: 'link'
    },
    {
      label: t('landingPage.mainMenu.presidentWord'),
      key: 'president',
      icon: <FontAwesomeIcon icon={faPerson} style={{ color: configValue.iconColor }} />,
      path: '#president',
      type: 'anchor'

    },
    {
      label: t('landingPage.mainMenu.ourTeam'),
      key: 'teams',
      icon: <FontAwesomeIcon icon={faUsersBetweenLines} style={{ color: configValue.iconColor }} />,
      path: '#teams',
      type: 'anchor'
    },
    {
      label: t('landingPage.mainMenu.logout'),
      key: 'login',
      icon: <FontAwesomeIcon icon={faClose} style={{ color: configValue.iconColor }} />,
      path: 'Singout',
      type: 'function'
    },
    {
      label: t('landingPage.mainMenu.contact'),
      key: 'Contact',
      icon: <FontAwesomeIcon icon={faLocationDot} style={{ color: configValue.iconColor }} />,
      path: '#Contact',
      type: 'anchor'

    },
  ]

  const menuNotConnected = [
    {
      label: t('landingPage.mainMenu.home'),
      key: 'home',
      icon: <FontAwesomeIcon icon={faHouse} style={{ color: configValue.iconColor }} />,
      path: '/',
      type: 'link'
    },
    {
      label: t('landingPage.mainMenu.presidentWord'),
      key: 'president',
      icon: <FontAwesomeIcon icon={faPerson} style={{ color: configValue.iconColor }} />,
      path: '#president',
      type: 'anchor'

    },
    {
      label: t('landingPage.mainMenu.ourTeam'),
      key: 'teams',
      icon: <FontAwesomeIcon icon={faUsersBetweenLines} style={{ color: configValue.iconColor }} />,
      path: '#teams',
      type: 'anchor'
    },
    {
      label: t('landingPage.mainMenu.login'),
      key: 'login',
      icon: <FontAwesomeIcon icon={faKey} style={{ color: configValue.iconColor }} />,
      path: '/Singin',
      type: 'link'
    },
    {
      label: t('landingPage.mainMenu.register'),
      key: 'register',
      icon: <FontAwesomeIcon icon={faIdCard} style={{ color: configValue.iconColor }} />,
      path: '/Singup',
      type: 'link'
    },
    {
      label: t('landingPage.mainMenu.contact'),
      key: 'Contact',
      icon: <FontAwesomeIcon icon={faLocationDot} style={{ color: configValue.iconColor }} />,
      path: '#Contact',
      type: 'anchor'

    },
  ]

  return {
    menuConnected,
    menuNotConnected
  }
}
