import React, { useState, useEffect } from 'react'

import { Row, Col } from 'antd';
import { usePlateformConfig, useSiteConfig } from '../../reduxhooks/ReduxHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function InfosBars() {

    const plateformConfig = usePlateformConfig();
    const configValue = useSiteConfig();

 
    const texts = plateformConfig.infosBar.filter(items => items.infoActive === true);

 
    const temptexts = [
        'Première ligne de texte',
        'Deuxième ligne de texte',
        'Troisième ligne de texte',
        'Quatrième ligne de texte'
    ];
    const animations = [
        'animate-fade-in',
        'animate-bounce-in',
        'animate-shake',
        'animate-fade-in-from-left',
        'animate-fade-in-from-right',
        'animate-fade-in-from-top',
        'animate-fade-in-from-bottom',
        'animate-zoom-in',
        'animate-bounce'
    ];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentText, setCurrentText] = useState(texts[currentIndex]);
    const [currentAnimation, setCurrentAnimation] = useState('');
    const [isMobile, setIsMobile] = useState(false);


    const getRandomAnimationIndex = (max) => Math.floor(Math.random() * max);

    useEffect(() => {

        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);

        }, 5000);
        return () => {
            clearInterval(interval);
        }
    }, []);


    useEffect(() => {
        setCurrentAnimation(animations[getRandomAnimationIndex(animations.length)]);

        setCurrentText(texts[currentIndex]);
    }, [currentIndex]);

    useEffect(() => {

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1000);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='container-main-landing d-flex align-items-center'>

            {isMobile ? (
                <div className={`text-container ${currentAnimation}`}>
                     {currentText.infoIcon && <FontAwesomeIcon icon={['fas', currentText.infoIcon]} style={{ color: 'white', fontSize: '16px', paddingRight : '5px' }} />} 
                    <div className="line">{currentText.info}</div>
                </div>
            ) : (
                <div className={`text-container ${currentAnimation}`}>
                    <Row gutter={[16, 16]} style={{ width: '50%' }}>
                        <Col >

                            {currentText.infoIcon && <FontAwesomeIcon icon={['fas', currentText.infoIcon]} style={{ color: 'white', fontSize: '16px', paddingRight: '5px' }} />}
                            <div className="line">{currentText.info}</div>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} style={{ width: '50%' }}>
                        <Col>
                            {texts[currentIndex + 1]?.infoIcon && <FontAwesomeIcon icon={['fas', texts[currentIndex + 1]?.infoIcon]} style={{ color: 'white', fontSize: '16px', paddingRight: '5px' }} />}
                            <div className="line">{texts[currentIndex + 1]?.info}</div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    )
}

