import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

import { useIsLoggedIn } from "../reduxhooks/ReduxHooks";


const RequireAuth = () => {

    const userLoggedState = useIsLoggedIn();

    const location = useLocation();

    return (
        
      userLoggedState.logged ? 
            <Outlet />
            : <Navigate to="/Singin" state={{ from: location }} replace />
    );
}

export default RequireAuth;