import { createSlice } from '@reduxjs/toolkit';


let initialState = {
  isSocketLoading: false,
  msg : ''
}


export const isSocketPMLoading = createSlice(
  {
    name: 'isSocketPMLoading',
    initialState,
    reducers: {

      isSocketPMLoadingItems: (oldState, { payload }) => {
        return {
          ...oldState,
          ...payload,
        }
      }
    },
  });

export const { isSocketPMLoadingItems } = isSocketPMLoading.actions;

export const stateisSocketPMLoading = state => state.isSocketPMLoading;

export default isSocketPMLoading.reducer;
