import { useSelector } from 'react-redux';
import { statesiteConfig } from '../reducers/slices/siteConfig.slice'; 
import { statemenuConfig } from '../reducers/slices/menuConfig.slice'; 
import { stateisLoggedIn } from '../reducers/slices/isLoggedIn.slice'; 
import { stateplateformConfig } from '../reducers/slices/plateformConfig.slice';
import { statesingupConfig } from '../reducers/slices/singupConfig.slice';
import { stateisSocketUMLoading } from '../reducers/slices/isSocketUMLoading.slice';
import { stateisSocketPMLoading } from '../reducers/slices/isSocketPMLoading.slice';
import { statecarouselConfig } from '../reducers/slices/carouselConfig.slice';

export const usePlateformConfig = () => {
  return useSelector(stateplateformConfig);
};

export const useSiteConfig = () => {
  return useSelector(statesiteConfig);
};

export const useMenuConfig = () => {
  return useSelector(statemenuConfig);
};

export const useIsLoggedIn = () => {
  return useSelector(stateisLoggedIn);
}

export const useSingupConfig = () => {
  return useSelector(statesingupConfig);
}

export const useSocketUMLoading = () => {
  return useSelector(stateisSocketUMLoading);
}

export const useSocketPMLoading = () => {
  return useSelector(stateisSocketPMLoading);
}

export const useCarouselConfig = () => {
  return useSelector(statecarouselConfig);
}