import { createSlice } from '@reduxjs/toolkit';


let initialState = {

}



export const menuConfig = createSlice(
  {
    name: 'menuConfig',
    initialState,
    reducers: {

      itemsMenu: (oldState, { payload }) => {
        return {
          ...oldState,
          ...payload,
        }
      }
    },
  });

export const { itemsMenu } = menuConfig.actions;

export const statemenuConfig = state => state.menuConfig;

export default menuConfig.reducer;