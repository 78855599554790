import React from 'react';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import { theme } from 'antd';
import frFR from 'antd/locale/fr_FR';

import { statesiteConfig } from '../../reducers/slices/siteConfig.slice';

const ThemeApp = ({ children }) => {

  const configValue = useSelector(statesiteConfig);

  const dynamicTheme = {
    algorithm: theme.defaultAlgorithm,
    components: {
     
    },
    token: {
      fontFamily : configValue.fontFamily || 'REM', 
      colorPrimary: configValue.primaryColor ||  '#dd3386',
    }
  };

  return (
   
    <ConfigProvider theme={dynamicTheme} locale={frFR} >
      {children}
    </ConfigProvider>
   
  );
};

export default ThemeApp;


