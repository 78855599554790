import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { items } from '../../reducers/slices/siteConfig.slice';
import { itemsPlateform } from '../../reducers/slices/plateformConfig.slice';
import { itemsMenu } from '../../reducers/slices/menuConfig.slice';
import { itemsSingupConfig } from '../../reducers/slices/singupConfig.slice';
import { itemsCarousel } from '../../reducers/slices/carouselConfig.slice';

import defaultConfig from './defaultConfig.json';


import axios from 'axios';
import { USER_API_GET_WEBSITE_CONFIG } from '../../config';

export default function ParamsApp({ children }) {

    const [isLoading, setIsLoading] = useState(true);


    const dispatch = useDispatch();

    useEffect(() => {

        if (isLoading) {

            axios.get(`${USER_API_GET_WEBSITE_CONFIG}`, {

            }).then(response => {
                
             
                dispatch(itemsPlateform(response.data.plateformConfigItems[0]));
                dispatch(items(response.data.configItems[0]));
                dispatch(itemsMenu(response.data.menuItems[0]));
                dispatch(itemsSingupConfig(response.data.singupConfig[0]));
                dispatch(itemsCarousel(response.data.carouselItems));
                

                setIsLoading(false);
            }).catch(error => {
                dispatch(items(defaultConfig));
                setIsLoading(false);
            });


        }

    }, [isLoading]);

    return (
        !isLoading ?
            <>
                {children}
            </>
            : null
    )
}
