import { createSlice } from '@reduxjs/toolkit';


let initialState = {
  
}

export const plateformConfig = createSlice(
  {
    name: 'plateformConfig',
    initialState,
    reducers: {

      itemsPlateform: (oldState, { payload }) => {
        return {
          ...oldState,
          ...payload,
        }
      }
    },
  });

export const { itemsPlateform } = plateformConfig.actions;

export const stateplateformConfig = state => state.plateformConfig;

export default plateformConfig.reducer;