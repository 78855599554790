import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import './i18n';

import { AxiosInterceptor } from './validator/AxiosInterceptor';
import './index.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

// This exports the whole icon packs for Brand and Solid.


import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store';


import ThemeApp from './assets/themes/ThemeApp';
import ParamsApp from './assets/themes/ParamsApp';
import SocketInterceptor from './socket/SocketInterceptor';

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();


library.add(fab, fas)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>

      <BrowserRouter >
        <AxiosInterceptor>
          <SocketInterceptor>
            <ParamsApp>
              <ThemeApp>

                <Routes >

                  <Route path="/*" element={<App />} />

                </Routes>

              </ThemeApp>
            </ParamsApp>
          </SocketInterceptor>
        </AxiosInterceptor>
      </BrowserRouter>

    </PersistGate>
  </Provider >
  // </React.StrictMode>
);

reportWebVitals();
