import axios from 'axios';
import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { isLogged } from '../reducers/slices/isLoggedIn.slice';


const AxiosInterceptor = ({ children }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
   

    useEffect(() => {
       
        axios.interceptors.response.use((response) => {
         
            return response;
          
          }, function (error) {
            const { response } = error;
         
            if (response.status === 401) {
        
                const stateAuth = {
                    logged: false,
                    user: null,
                    roles: null
                };
                dispatch(isLogged(stateAuth));
                navigate('/Singin', {state:{ from: location }, replace : true})
            }
            if (response.status === 403) {
                navigate('/Unauthorized' , { replace: true });
            }

          
            return Promise.reject(error);
          }); 

    }, [navigate])

    return children; 
  
}



export { AxiosInterceptor }