import { createSlice } from '@reduxjs/toolkit';


let initialState = {

}

export const singupConfig = createSlice(
    {
        name: 'singupConfig',
        initialState,
        reducers: {

            itemsSingupConfig: (oldState, { payload }) => {
                return {
                    ...oldState,
                    ...payload,
                }
            }
        },
    });

export const { itemsSingupConfig } = singupConfig.actions;

export const statesingupConfig = state => state.singupConfig;

export default singupConfig.reducer;