import { createSlice } from '@reduxjs/toolkit';


let initialState = {
  isSocketLoading: false,
  msg : ''
}


export const isSocketUMLoading = createSlice(
  {
    name: 'isSocketUMLoading',
    initialState,
    reducers: {

      isSocketUMLoadingItems: (oldState, { payload }) => {
        return {
          ...oldState,
          ...payload,
        }
      }
    },
  });

export const { isSocketUMLoadingItems } = isSocketUMLoading.actions;

export const stateisSocketUMLoading = state => state.isSocketUMLoading;

export default isSocketUMLoading.reducer;
