import { createSlice } from '@reduxjs/toolkit';


let initialState = {
  primaryColor: '#336622', 
  infosBarBkColor : 'yellow'
}



export const siteConfig = createSlice(
  {
    name: 'siteConfig',
    initialState,
    reducers: {

      items: (oldState, { payload }) => {
        return {
          ...oldState,
          ...payload,
        }
      }
    },
  });

export const { items } = siteConfig.actions;

export const statesiteConfig = state => state.siteConfig;

export default siteConfig.reducer;