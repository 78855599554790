import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

import { useIsLoggedIn } from "../reduxhooks/ReduxHooks";

const RequireRole = (props) => {

    const userLoggedState = useIsLoggedIn();

    const location = useLocation();

    return (
        !userLoggedState.logged ? <Navigate to="/Singin" state={{ from: location }} replace /> :

            props.allowedRoles.includes(userLoggedState.roles)
                ? <Outlet />
                : <Navigate to="/unauthorized" state={{ from: location }} replace />

    );
}

export default RequireRole;