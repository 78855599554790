import { createSlice } from '@reduxjs/toolkit';


let initialState = {

}



export const carouselConfig = createSlice(
  {
    name: 'carouselConfig',
    initialState,
    reducers: {

      itemsCarousel: (oldState, { payload }) => {
        return {
          //...oldState,
          ...payload,
        }
      }
    },
  });

export const { itemsCarousel } = carouselConfig.actions;

export const statecarouselConfig = state => state.carouselConfig;

export default carouselConfig.reducer;