import React from 'react'

import SVG from 'react-inlinesvg';

import ring from '../../assets/medias/spinner/ring.svg';

import './spinnerPage.scss'

export default function SpinnerPage() {
    return (
        <div className='container-spinner-page'>
            <SVG src={ring} />
        </div>
    )
}
